import React from "react";
var __jsx = React.createElement;
import "../styles/globals.scss";
import "../styles/arabicStyles.scss";
import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";

function MyApp(_ref) {
  var Component = _ref.Component,
      pageProps = _ref.pageProps;
  useEffect(function () {
    import("bootstrap/dist/js/bootstrap"); // Initialize Intercom

    window.Intercom('boot');
  }, []); // Use the layout defined at the page level, if available

  var getLayout = Component.getLayout || function (page) {
    return page;
  };

  return getLayout(__jsx(Component, pageProps));
}

export default MyApp;